import React, { FC, memo } from 'react';
import { Box, Typography, RadioGroup, FormControlLabel } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import BoxComponent from '../../custom/BoxComponent';
import CustomRadio from '../../custom/CustomRadio';
// import CustomMenuItem from '../../custom/CustomMenuItem';
// import CustomSelect from '../../custom/CustomSelect';
// import CustomSwitch from '../../custom/CustomSwitch';
import { CshoreModelFeatures } from '../../../models/inputTypes/CshoreFields';
// import { FileType } from '../../../models/inputTypes/FileType';
// import ButtonFileDialog from '../../common/ButtonFileDialog';
import { cshoreModelFeaturesInitialState } from '../../../utils/initialStates/cshoreInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceBetweenBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: '900px',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
} as const;
type InputsProps = {
  sediment?: boolean;
  //  projectId: string;
  inputState: CshoreModelFeatures;
  setInputState: (value: CshoreModelFeatures) => void;
};
const CshoreModelFeaturesInputsGroup: FC<InputsProps> = ({
  sediment = false,
  // projectId,
  inputState,
  setInputState,
}) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as any;
    let rwh = cshoreModelFeaturesInitialState.rwh;
    if (newValue == 'lab') {
      rwh = 0.01;
    }
    console.log(newValue, rwh);
    if (e.target.name === 'ilabType') {
      setInputState({ ...inputState, ilabType: newValue, rwh: rwh });
      return;
    }
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Sediment Properties
      </Typography>
      <BoxComponent disabled={!sediment} sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'Sediment density (kg/m\u00B3)'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.density}
              name={'density'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'d50 (mm)'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              // warningText={d50Warning}
              value={inputState.d50}
              name={'d50'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Fall velocity (m/s)'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.velocity}
              name={'velocity'}
            />
          </LabelWrapper>
        </Box>
      </BoxComponent>
      <Typography mt={3} mb={1} variant={'subtitle1'}>
        Sediment Transport
      </Typography>
      <BoxComponent disabled={!sediment} sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'eb'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.eb}
              name={'eb'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'ef'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.ef}
              name={'ef'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'slp'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.slp}
              name={'slp'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'slpot'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.slpot}
              name={'slpot'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'tanφ'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.tan}
              name={'tan'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'blp'}>
            <CustomInput
              disabled={!sediment}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.blp}
              name={'blp'}
            />
          </LabelWrapper>
        </Box>
      </BoxComponent>
      <Typography mt={2} mb={1} variant={'subtitle1'}>
        Scale Dimensions
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing, ...styles.wrap }}>
        <Box>
          <RadioGroup value={inputState.ilabType} onChange={onChangeRadio} aria-label="ilabType" name="ilabType">
            <FormControlLabel
              labelPlacement={'end'}
              value="field"
              control={<CustomRadio />}
              label={<Typography variant={'subtitle2'}>Field</Typography>}
            />
            <FormControlLabel
              labelPlacement={'end'}
              value="lab"
              control={<CustomRadio />}
              label={<Typography variant={'subtitle2'}>Lab</Typography>}
            />
          </RadioGroup>
        </Box>
        <Box>
          <LabelWrapper label={'rwh'}>
            <CustomInput
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              // warningText={d50Warning}
              value={inputState.rwh}
              name={'rwh'}
            />
          </LabelWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(CshoreModelFeaturesInputsGroup);
