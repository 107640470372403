import {
  CshoreModelDefinition,
  CshoreNumericalDomain,
  CshoreDissipation,
  CshoreModelFeatures,
} from '../../models/inputTypes/CshoreFields';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { CshoreType } from '../../models/jobModels/cshoreType';
import { dynamicInputFilePreparation } from '../functions/dynamicInputFilePreparation';

export const cshorePreparation = (
  numericalState: CshoreNumericalDomain,
  modelDefinitionState: CshoreModelDefinition,
  modelFeaturesState: CshoreModelFeatures,
  dissipationState: CshoreDissipation,
  submissionState: PmsSubmission,
): CshoreType | unknown => {
  const inputFiles = dynamicInputFilePreparation({ I_Depths: numericalState.profileFile });

  const preparedValues: CshoreType = {
    simulation: 'cshore',
    project: numericalState.project.toString(),
    cores: +submissionState.vCpus,
    name: submissionState.simulationFile,
    parameters: {
      input_files: inputFiles,
      // output_files: [...outputArea, ...outputLine],

      cellX: numericalState.cellX,
      cellSizeX: numericalState.cellSizeX,

      sed: modelDefinitionState.sed ? 1 : 0,
      over: modelDefinitionState.over ? 1 : 0,
      roll: modelDefinitionState.roll ? 1 : 0,
      veg: modelDefinitionState.veg ? 1 : 0,

      density: modelFeaturesState.density,
      d50: modelFeaturesState.d50,
      velocity: modelFeaturesState.velocity,
      eb: modelFeaturesState.eb,
      ef: modelFeaturesState.ef,
      slp: modelFeaturesState.slp,
      slpot: modelFeaturesState.slpot,
      tan: modelFeaturesState.tan,
      blp: modelFeaturesState.blp,
      ilab: modelFeaturesState.ilabType === 'field' ? 0 : 1,
      rwh: modelFeaturesState.rwh,

      brk_f: dissipationState.waveBreaking ? 1 : 0,
      brk_coef_y: +dissipationState.y,
      frctnType: dissipationState.bottomFriction ? 1 : 0,
      frctn: dissipationState.waveBreakingType === 'varying' ? 1 : 0,
      frctn_coef: +dissipationState.nikuradse,
    },
  };
  return preparedValues;
};
