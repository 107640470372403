import React, { FC } from 'react';
import ButtonFileDialog from '../ButtonFileDialog';
import { FileType } from '../../../models/inputTypes/FileType';
import { openWarningToast } from '../../../redux/slices/appSlice';
import { useAppDispatch } from '../../../redux/store';
import { useLazyBathymetricFileSizeQuery, useLazyProfileFileSizeQuery } from '../../../redux/RTK/queries/fileQuery';
import { srfNumericalDomainInitialState } from '../../../utils/initialStates/SrfInputState';
// import { cshoreNumericalDomainInitialState } from '../../../utils/initialStates/CshoreInputState';

type SimulationFileContainerProps = {
  file?: FileType;
  project: string;
  typePage: 'BTG' | 'SRF' | 'EVI' | 'PMS' | 'HMS' | 'HYD' | 'SDT' | 'PLOT' | 'CSHORE' | 'ALL';
  setBathymetricInputs: (values: { file: FileType; cellX?: number; cellY?: number }) => void;
  requestDims?: boolean;
  requestLen?: boolean;
};
const BathymetricFileContainer: FC<SimulationFileContainerProps> = ({
  setBathymetricInputs,
  typePage,
  file,
  requestDims = true,
  requestLen = false,
  project,
}) => {
  const [fetchCsv] = useLazyBathymetricFileSizeQuery();
  const [fetchCsvLen] = useLazyProfileFileSizeQuery();
  const dispatch = useAppDispatch();

  const onBathymetricFileChange = async (newFile?: FileType) => {
    if (requestLen) {
      const fileName = newFile?.currentFile?.name;
      const project = newFile?.currentFile?.project;
      if (fileName && project && fileName.endsWith('.csv')) {
        try {
          const result = await fetchCsvLen({ projectId: project, fileName });
          const cellX: number = result?.data?.['Number of cells'] ?? srfNumericalDomainInitialState.cellX;
          return setBathymetricInputs({ file: newFile, cellX: cellX });
        } catch (e) {
          return setBathymetricInputs({
            file: newFile,
            cellX: srfNumericalDomainInitialState.cellX,
          });
        }
      } else dispatch(openWarningToast('File type must be CSV'));
    }
    if (!requestDims && newFile) {
      return setBathymetricInputs({ file: newFile });
    }
    const fileName = newFile?.currentFile?.name;
    const project = newFile?.currentFile?.project;
    if (fileName && project && fileName.endsWith('.csv')) {
      try {
        const result = await fetchCsv({ projectId: project, fileName });
        const cellX: number = result?.data?.['Cells in x direction'] ?? srfNumericalDomainInitialState.cellX;
        const cellY: number = result?.data?.['Cells in y direction'] ?? srfNumericalDomainInitialState.cellY;
        setBathymetricInputs({ file: newFile, cellX, cellY });
      } catch (e) {
        setBathymetricInputs({
          file: newFile,
          cellX: srfNumericalDomainInitialState.cellX,
          cellY: srfNumericalDomainInitialState.cellY,
        });
      }
    } else dispatch(openWarningToast('File type must be CSV'));
  };

  return (
    <ButtonFileDialog
      typePage={typePage}
      dataFilesOnly
      projectId={project}
      disabled={!project}
      required
      file={file}
      setFile={onBathymetricFileChange}
    />
  );
};

export default BathymetricFileContainer;
