import { SrfSubmission } from '../../models/inputTypes/SrfFields';
import { cshoreNumericalDomainInitialState } from '../initialStates/cshoreInputState';
import { srfSubmissionInitialState } from '../initialStates/SrfInputState';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { OutputFile } from '../../models/jobModels/ModelsFileTypes';
import { prepareInputFileSimulation } from '../functions/prepareInputFileSimulation';
import { findOutputFileName } from '../functions/findOutputFileName';
import { CshoreType } from '../../models/jobModels/cshoreType';
import {
  CshoreModelDefinition,
  CshoreNumericalDomain,
  CshoreDissipation,
  CshoreModelFeatures,
} from '../../models/inputTypes/CshoreFields';

export type CshoreSimulationPreparationResponse = {
  numerical: CshoreNumericalDomain;
  modelDefinition: CshoreModelDefinition;
  modelFeatures: CshoreModelFeatures;
  energyDissipation: CshoreDissipation;
  submission: PmsSubmission;
};

export const cshoreSimulationPreparation = (
  simulationFile: CshoreType,
): CshoreSimulationPreparationResponse | undefined => {
  const I_Profile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Profile.csv');
  const frictionFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Friction_Map.csv');

  const numerical: CshoreNumericalDomain = {
    project: simulationFile.project ? simulationFile.project.toString() : cshoreNumericalDomainInitialState.project,
    profileFile: I_Profile,
    cellX: simulationFile.parameters.cellX,
    cellSizeX: simulationFile.parameters.cellSizeX,
  };

  const modelDefinition: CshoreModelDefinition = {
    sed: simulationFile.parameters.sed == 1,
    over: simulationFile.parameters.over == 1,
    roll: simulationFile.parameters.roll == 1,
    veg: simulationFile.parameters.veg == 1,
  };

  const modelFeatures: CshoreModelFeatures = {
    density: simulationFile.parameters.density,
    d50: simulationFile.parameters.d50,
    velocity: simulationFile.parameters.velocity,
    eb: simulationFile.parameters.eb,
    ef: simulationFile.parameters.ef,
    slp: simulationFile.parameters.slp,
    slpot: simulationFile.parameters.slpot,
    tan: simulationFile.parameters.tan,
    blp: simulationFile.parameters.blp,
    ilabType: simulationFile.parameters.ilab == 0 ? 'field' : 'lab',
    rwh: simulationFile.parameters.rwh,
  };

  const energyDissipation: CshoreDissipation = {
    waveBreaking: simulationFile.parameters.brk_f == 1,
    y: +simulationFile.parameters.brk_coef_y,
    bottomFriction: simulationFile.parameters.frctn == 1,
    waveBreakingType: simulationFile.parameters.frctnType == 1 ? 'varying' : 'constant',
    nikuradse: simulationFile.parameters.frctn_coef.toString(),
    frictionFile: frictionFile,
  };

  const submission: SrfSubmission = {
    vCpus: simulationFile.cores ? simulationFile.cores : srfSubmissionInitialState.vCpus,
    simulationFile: srfSubmissionInitialState.simulationFile,
  };

  return { numerical, modelDefinition, modelFeatures, energyDissipation, submission };
};
