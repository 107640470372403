import {
  CshoreModelDefinition,
  CshoreNumericalDomain,
  CshoreDissipation,
  CshoreModelFeatures,
} from '../../models/inputTypes/CshoreFields';

export const cshoreNumericalDomainInitialState: CshoreNumericalDomain = {
  project: '',
  simulationSetup: undefined,

  cellX: 1,
  cellSizeX: 1,

  profileFile: undefined,
};

export const cshoreModelDefinitionInitialState: CshoreModelDefinition = {
  sed: true,
  over: true,
  roll: false,
  veg: false,
};

export const cshoreModelFeaturesInitialState: CshoreModelFeatures = {
  density: 2.65,
  d50: 0.3,
  velocity: 0.04,
  eb: 0.005,
  ef: 0.01,
  slp: 0.5,
  slpot: 0.1,
  tan: 0.63,
  blp: 0.001,
  ilabType: 'field',
  rwh: 0.05,
};

export const cshoreDissipationInitialState: CshoreDissipation = {
  waveBreaking: true,
  y: 1,
  bottomFriction: true,
  waveBreakingType: 'constant',
  nikuradse: '0.002',
  frictionFile: undefined,
};
