import React, { FC, memo } from 'react';
import { Box, Grid } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomRadioButton from '../../custom/CustomRadioButton';
import BoxComponent from '../../custom/BoxComponent';
import { CshoreModelDefinition } from '../../../models/inputTypes/CshoreFields';
// import { cshoreModelDefinitionInitialState } from '../../../utils/initialStates/cshoreInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
} as const;

type InputsProps = {
  inputState: CshoreModelDefinition;
  setInputState: (value: CshoreModelDefinition) => void;
};

const CshoreModelDefinitionInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  // const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   setInputState({ ...inputState, [event.target.name]: event.target.value });
  // };

  // const onRadioClick = (value: any) => {
  //   setInputState({
  //     ...inputState,
  //     stationaryHyd: cshoreModelDefinitionInitialState.stationaryHyd,
  //     varyingHyd: cshoreModelDefinitionInitialState.varyingHyd,
  //     varyingWave: cshoreModelDefinitionInitialState.varyingWave,
  //     stationaryWave: cshoreModelDefinitionInitialState.stationaryWave,
  //     simulationTime: cshoreModelDefinitionInitialState.simulationTime,
  //     calculationType: value,
  //   });
  // };

  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  return (
    <Box>
      <Box>
        <LabelWrapper label={'Include sediment transport'}>
          <Grid container justifyContent={'center'} width={'120px'}>
            <CustomSwitch checked={inputState.sed} name={'sed'} onChange={onChangeSwitch} />
          </Grid>
        </LabelWrapper>
      </Box>
      <Box mt={2}>
        <LabelWrapper label={'Include wave overtopping'}>
          <Grid container justifyContent={'center'} width={'120px'}>
            <CustomSwitch checked={inputState.over} name={'over'} onChange={onChangeSwitch} />
          </Grid>
        </LabelWrapper>
      </Box>
      <Box mt={2}>
        <LabelWrapper label={'Include roller effect'}>
          <Grid container justifyContent={'center'} width={'120px'}>
            <CustomSwitch checked={inputState.roll} name={'roll'} onChange={onChangeSwitch} />
          </Grid>
        </LabelWrapper>
      </Box>
      <Box mt={2}>
        <LabelWrapper label={'Include vegetation'}>
          <Grid container justifyContent={'center'} width={'120px'}>
            <CustomSwitch checked={inputState.veg} name={'veg'} onChange={onChangeSwitch} />
          </Grid>
        </LabelWrapper>
      </Box>
    </Box>
  );
};

export default memo(CshoreModelDefinitionInputsGroup);
