import React, { FC, memo, useMemo } from 'react';
import { Box, RadioGroup, SelectChangeEvent, Typography, Grid } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import CustomRadioButton from '../../custom/CustomRadioButton';
import BoxComponent from '../../custom/BoxComponent';
import CustomSelect from '../../custom/CustomSelect';
import CustomMenuItem from '../../custom/CustomMenuItem';
import { ShorelineWaveClima, WaveGenObject } from '../../../models/inputTypes/ShorelineFields';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { shorelineWaveClimaInitialState, WaveGenInitialObject } from '../../../utils/initialStates/shorelineInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      // my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
} as const;

type InputsProps = {
  projectId: string;
  inputState: ShorelineWaveClima;
  setInputState: (value: ShorelineWaveClima) => void;
  trformulation: string;
};

const ShorelineWaveClimaInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState, trformulation }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onRadioClick = (value: any) => {
    setInputState({
      ...inputState,
      hm: shorelineWaveClimaInitialState.hm,
      ts: shorelineWaveClimaInitialState.ts,
      direction: shorelineWaveClimaInitialState.direction,
      spread: shorelineWaveClimaInitialState.spread,
      timeseriesFile: undefined,
      waveclimaFile: undefined,
      numTime: shorelineWaveClimaInitialState.numTime,
      timeseries: shorelineWaveClimaInitialState.timeseries,
      numWave: shorelineWaveClimaInitialState.numWave,
      waveclima: shorelineWaveClimaInitialState.waveclima,
      wavegenType: value,
    });
  };

  const disabled = useMemo(() => {
    return ['CERC', 'CERC2', 'CERC3'].includes(trformulation);
  }, [trformulation]);

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = event.target.value as any;
    if (event.target.name === 'numTime') {
      const timeseries: WaveGenObject[] = [];
      for (let i = 0; i < newValue; i++) {
        if (inputState.timeseries[i]) {
          timeseries.push(inputState.timeseries[i]);
        } else {
          timeseries.push(WaveGenInitialObject);
        }
      }
      setInputState({
        ...inputState,
        [event.target.name]: newValue,
        timeseries: timeseries,
        timeseriesFile: undefined,
      });
      return;
    }
    if (event.target.name === 'numWave') {
      const waveclima: WaveGenObject[] = [];
      for (let i = 0; i < newValue; i++) {
        if (inputState.waveclima[i]) {
          waveclima.push(inputState.waveclima[i]);
        } else {
          waveclima.push(WaveGenInitialObject);
        }
      }
      setInputState({
        ...inputState,
        [event.target.name]: newValue,
        waveclima: waveclima,
        waveclimaFile: undefined,
      });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onDynamicFileChange = (file: FileType | undefined, fieldName: string, index: number) => {
    if (fieldName == 'time') {
      const newCodeArray = inputState.timeseries.slice();
      newCodeArray[index] = { ...newCodeArray[index], file: file };
      setInputState({ ...inputState, timeseries: newCodeArray });
    }
    if (fieldName == 'wave') {
      const newCodeArray = inputState.waveclima.slice();
      newCodeArray[index] = { ...newCodeArray[index], file: file };
      setInputState({ ...inputState, waveclima: newCodeArray });
    }
  };

  const onDynamicInputChange = (
    index: number,
    e: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: string,
  ) => {
    if (type == 'time') {
      const newCodeArray = inputState.timeseries.slice();
      newCodeArray[index] = { ...newCodeArray[index], [e.target.name]: e.target.value };
      setInputState({ ...inputState, timeseries: newCodeArray });
      return;
    }
    if (type == 'wave') {
      const newCodeArray = inputState.waveclima.slice();
      newCodeArray[index] = { ...newCodeArray[index], [e.target.name]: e.target.value };
      setInputState({ ...inputState, waveclima: newCodeArray });
      return;
    }
  };

  const timeseries = useMemo(() => {
    if (inputState.numTime == 1) {
      return (
        <Grid item>
          <BoxComponent disabled={inputState.wavegenType !== 'timeseries'}>
            <LabelWrapper label={'Insert timeseries file'}>
              <ButtonFileDialog
                typePage={'SHORELINES'}
                dataTxtFilesOnly
                required
                projectId={projectId}
                disabled={!projectId || inputState.wavegenType !== 'timeseries'}
                file={inputState.timeseriesFile}
                setFile={(file) => onFileChange(file, 'timeseriesFile')}
              />
            </LabelWrapper>
          </BoxComponent>
        </Grid>
      );
    } else {
      return inputState.timeseries.map((item, i) => (
        <Grid key={i} item>
          <BoxComponent
            my={1}
            disabled={inputState.wavegenType !== 'timeseries'}
            sx={{ ...styles.flexStartBox, ...styles.childSpacing }}
          >
            <Box>
              <LabelWrapper label={'Insert timeseries file'}>
                <ButtonFileDialog
                  typePage={'SHORELINES'}
                  dataTxtFilesOnly
                  required
                  projectId={projectId}
                  disabled={!projectId || inputState.wavegenType !== 'timeseries'}
                  file={item.file}
                  setFile={(file) => onDynamicFileChange(file, 'time', i)}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'X coordinates'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={item.X}
                  onChange={(e) => onDynamicInputChange(i, e, 'time')}
                  name={'X'}
                  maxWidth={'100px'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Y coordinates'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={item.Y}
                  onChange={(e) => onDynamicInputChange(i, e, 'time')}
                  name={'Y'}
                  maxWidth={'100px'}
                />
              </LabelWrapper>
            </Box>
          </BoxComponent>
        </Grid>
      ));
    }
  }, [inputState.timeseries, inputState.numTime, projectId, inputState.wavegenType, inputState.timeseriesFile]);

  const waveclima = useMemo(() => {
    if (inputState.numWave == 1) {
      return (
        <Grid item>
          <BoxComponent disabled={inputState.wavegenType !== 'wave_climate'}>
            <LabelWrapper label={'Insert wave climate file'}>
              <ButtonFileDialog
                typePage={'SHORELINES'}
                dataTxtFilesOnly
                required
                projectId={projectId}
                disabled={!projectId || inputState.wavegenType !== 'wave_climate'}
                file={inputState.waveclimaFile}
                setFile={(file) => onFileChange(file, 'waveclimaFile')}
              />
            </LabelWrapper>
          </BoxComponent>
        </Grid>
      );
    } else {
      return inputState.waveclima.map((item, i) => (
        <Grid key={i} item>
          <BoxComponent
            my={1}
            disabled={inputState.wavegenType !== 'wave_climate'}
            sx={{ ...styles.flexStartBox, ...styles.childSpacing }}
          >
            <Box>
              <LabelWrapper label={'Insert wave climate file'}>
                <ButtonFileDialog
                  typePage={'SHORELINES'}
                  dataTxtFilesOnly
                  required
                  projectId={projectId}
                  disabled={!projectId || inputState.wavegenType !== 'wave_climate'}
                  file={item.file}
                  setFile={(file) => onDynamicFileChange(file, 'wave', i)}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'X coordinates'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={item.X}
                  onChange={(e) => onDynamicInputChange(i, e, 'wave')}
                  name={'X'}
                  maxWidth={'100px'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Y coordinates'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={item.Y}
                  onChange={(e) => onDynamicInputChange(i, e, 'wave')}
                  name={'Y'}
                  maxWidth={'100px'}
                />
              </LabelWrapper>
            </Box>
          </BoxComponent>
        </Grid>
      ));
    }
  }, [inputState.waveclima, inputState.numWave, projectId, inputState.wavegenType, inputState.waveclimaFile]);

  return (
    <>
      <Typography mb={1} variant={'subtitle1'}>
        Wave Generation
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box width={'120px'}>
          <CustomRadioButton
            checked={inputState.wavegenType === 'constant'}
            value={'constant'}
            onButtonClick={onRadioClick}
            textLabel={'Constant'}
          />
        </Box>
        <BoxComponent
          sx={{ ...styles.flexStartBox, ...styles.childSpacing }}
          disabled={inputState.wavegenType !== 'constant'}
        >
          <Box>
            <LabelWrapper label={'Hs (m)'}>
              <CustomInput
                disabled={inputState.wavegenType !== 'constant'}
                min={0.0000000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.hm}
                onChange={onNumberChange}
                name={'hm'}
                maxWidth={'65px'}
              />
            </LabelWrapper>
          </Box>
          <BoxComponent disabled={disabled}>
            <LabelWrapper label={'Tp (s)'}>
              <CustomInput
                disabled={inputState.wavegenType !== 'constant'}
                min={0.0000000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.ts}
                onChange={onNumberChange}
                name={'ts'}
                maxWidth={'65px'}
              />
            </LabelWrapper>
          </BoxComponent>
          <Box>
            <LabelWrapper label={'Direction (deg)'}>
              <CustomInput
                disabled={inputState.wavegenType !== 'constant'}
                min={0}
                max={359.99}
                required
                step={'any'}
                errorText={'Enter number 0-359.99'}
                type={'number'}
                value={inputState.direction}
                onChange={onNumberChange}
                name={'direction'}
                maxWidth={'100px'}
              />
            </LabelWrapper>
          </Box>
        </BoxComponent>
      </Box>
      <Box my={2} width={'1200px'}>
        <Grid container spacing={2}>
          {/* First column */}
          <Grid item style={{ width: '200px' }}>
            <Box>
              <CustomRadioButton
                checked={inputState.wavegenType === 'timeseries'}
                value={'timeseries'}
                onButtonClick={onRadioClick}
                textLabel={'Timeseries'}
              />
            </Box>
          </Grid>
          {/* Second column */}
          <Grid item style={{ width: '250px' }}>
            <BoxComponent disabled={inputState.wavegenType !== 'timeseries'}>
              <LabelWrapper label={'Locations'}>
                <CustomSelect
                  value={inputState.numTime}
                  onChange={onInputChange}
                  name={'numTime'}
                  defaultValue={1}
                  minWidth={'100px'}
                  displayEmpty
                  disabled={inputState.wavegenType !== 'timeseries'}
                >
                  <CustomMenuItem value={1}>
                    <Typography variant={'subtitle2'}>{1}</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={2}>
                    <Typography variant={'subtitle2'}>{2}</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={3}>
                    <Typography variant={'subtitle2'}>{3}</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={4}>
                    <Typography variant={'subtitle2'}>{4}</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={5}>
                    <Typography variant={'subtitle2'}>{5}</Typography>
                  </CustomMenuItem>
                </CustomSelect>
              </LabelWrapper>
            </BoxComponent>
          </Grid>
          {/* Third column */}
          <Grid item style={{ width: '600px' }}>
            <Grid container direction="column" spacing={1}>
              {timeseries}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box my={2} width={'1200px'}>
        <Grid container spacing={2}>
          {/* First column */}
          <Grid item style={{ width: '200px' }}>
            <Box>
              <CustomRadioButton
                checked={inputState.wavegenType === 'wave_climate'}
                value={'wave_climate'}
                onButtonClick={onRadioClick}
                textLabel={'Wave Climate'}
              />
            </Box>
          </Grid>
          {/* Second column */}
          <Grid item style={{ width: '250px' }}>
            <BoxComponent disabled={inputState.wavegenType !== 'wave_climate'}>
              <LabelWrapper label={'Locations'}>
                <CustomSelect
                  value={inputState.numWave}
                  onChange={onInputChange}
                  name={'numWave'}
                  minWidth={'100px'}
                  defaultValue={1}
                  displayEmpty
                  disabled={inputState.wavegenType !== 'wave_climate'}
                >
                  <CustomMenuItem value={1}>
                    <Typography variant={'subtitle2'}>{1}</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={2}>
                    <Typography variant={'subtitle2'}>{2}</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={3}>
                    <Typography variant={'subtitle2'}>{3}</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={4}>
                    <Typography variant={'subtitle2'}>{4}</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={5}>
                    <Typography variant={'subtitle2'}>{5}</Typography>
                  </CustomMenuItem>
                </CustomSelect>
              </LabelWrapper>
            </BoxComponent>
          </Grid>
          {/* Third column */}
          <Grid item style={{ width: '600px' }}>
            <Grid container direction="column" spacing={1}>
              {waveclima}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <LabelWrapper label={'Directional spreading'}>
          <CustomInput
            required
            step={'any'}
            errorText={'Enter number'}
            type={'number'}
            onChange={onNumberChange}
            value={inputState.spread}
            name={'spread'}
            maxWidth={'80px'}
          />
        </LabelWrapper>
      </Box>
    </>
  );
};

export default memo(ShorelineWaveClimaInputsGroup);
