import {
  ShorelineNumericalDomain,
  ShorelineSimulationTime,
  ShorelineWaveClima,
  ShorelineModelFeatures,
  ShorelineStructure,
  ShorelineNourishment,
  ShorelinePlotProperties,
  ShorelinePlotLabelPdf,
  ShorelineOutput,
} from '../../models/inputTypes/ShorelineFields';

export const NourishmentsInitialObject = {
  swX: undefined,
  swY: undefined,
  neX: undefined,
  neY: undefined,
  rate: undefined,
  tstart: undefined,
  tend: undefined,
};

export const ShorefaceNourishmentsInitialObject = {
  swX: undefined,
  swY: undefined,
  neX: undefined,
  neY: undefined,
  nour_len: 10,
  d: -8,
  d50: 1,
  tstart: undefined,
  rate: undefined,
  k: undefined,
};

export const PlotInitialObject = { file: undefined, label: '', color: 'k' };

export const WaveGenInitialObject = { file: undefined, X: undefined, Y: undefined };

export const shorelineNumericalDomainInitialState: ShorelineNumericalDomain = {
  project: '',
  clockwise: false,
  simulationSetup: undefined,
  cellS: 100,
  coastlineFile: undefined,
  d: 10,
  ddeep: 25,
  dnearshore: 8,
  orientationType: 'auto',
  phif: undefined,
  orientationFile: undefined,
  ld: 3000,
  boundaryTypeStart: 'Fixed',
  boundaryTypeEnd: 'Fixed',
};

export const shorelineSimulationTimeInitialState: ShorelineSimulationTime = {
  reftime: new Date().toISOString().split('T')[0],
  simend: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0],
  timestep: 0,
  timeType: 'adaptive',
};

export const shorelineWaveClimaInitialState: ShorelineWaveClima = {
  wavegenType: 'constant',
  hm: 1,
  ts: 6,
  direction: 330,
  spread: 0,
  timeseriesFile: undefined,
  waveclimaFile: undefined,
  numTime: 1,
  timeseries: [],
  numWave: 1,
  waveclima: [],
};

export const shorelineModelFeaturesInitialState: ShorelineModelFeatures = {
  wave_diffraction: false,
  clima_change: false,
  slrate: 1,
  sediment: 'CERC',
  qscal: 1,
  calfc: 1e6,
  tper: 6,
  d50: 0.2,
  porosity: 0.4,
  tanbeta: 0.03,
  rhos: 2650,
  rhow: 1025,
  gamma: 0.72,
  smoothC: false,
  smoothfac: 0,
  smoothR: false,
  smoothrefrac: 0,
};

export const shorelineStructureInitialState: ShorelineStructure = {
  struct: false,
  structFile: undefined,
  revetment: false,
  revetFile: undefined,
};

export const shorelineNourishmentInitialState: ShorelineNourishment = {
  nourish: false,
  shoreface: false,
  numNourishments: 1,
  nourishments: [NourishmentsInitialObject],
  numShorefaceNour: 1,
  shorefaceNour: [ShorefaceNourishmentsInitialObject],
  coefType: 'auto',
};

export const shorelinePlotPropertiesInitialState: ShorelinePlotProperties = {
  swX: undefined,
  swY: undefined,
  neX: undefined,
  neY: undefined,
  fignryear: 12,
  usefill: true,
  plotwavequiver: false,
  plotX: undefined,
  plotY: undefined,
  scale: undefined,
  refCoast: [],
  pltObjNum: 0,
  llocation: 'SouthWest',
  pdf: false,
};

export const shorelinePlotLabelPdfInitialState: ShorelinePlotLabelPdf = {
  frame: 1,
  plotBasemap: false,
  inpEPSG: parseInt(''),
  mapProvider: 1,
  pageOrientation: '2',
  label: false,
  userIdType: 'username',
  username: '',
  logoFile: undefined,
  client: '',
  projectTitle: '',
  figureDescription: '',
  figureNumber: '',
  figureTitle: '',
  date: '',
};

export const shorelineOutputInitialState: ShorelineOutput = {
  outputFileName: '',
};
