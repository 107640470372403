import React, { FC } from 'react';
import ButtonFileDialog from '../ButtonFileDialog';
import { FileType } from '../../../models/inputTypes/FileType';
import { openWarningToast } from '../../../redux/slices/appSlice';
import { useAppDispatch } from '../../../redux/store';
import { useLazyCoastlineFileSizeQuery } from '../../../redux/RTK/queries/fileQuery';
import { shorelinePlotPropertiesInitialState } from '../../../utils/initialStates/shorelineInputState';

type SimulationFileContainerProps = {
  file?: FileType;
  project: string;
  typePage: 'SHORELINES' | 'ALL';
  setInputs: (values: { file: FileType; swX?: number; swY?: number; neX?: number; neY?: number }) => void;
  requestDims?: boolean;
};
const CoastlineFileContainer: FC<SimulationFileContainerProps> = ({ setInputs, typePage, file, project }) => {
  const [fetchFile] = useLazyCoastlineFileSizeQuery();
  const dispatch = useAppDispatch();

  const onCoastlineFileChange = async (newFile?: FileType) => {
    const fileName = newFile?.currentFile?.name;
    const project = newFile?.currentFile?.project;
    if (fileName && project) {
      try {
        const result = await fetchFile({ projectId: project, fileName });
        const xlimits: [number, number] | undefined = result?.data?.['x limits'];
        const ylimits: [number, number] | undefined = result?.data?.['y limits'];
        const [swX, neX] = xlimits ?? [undefined, undefined];
        const [swY, neY] = ylimits ?? [undefined, undefined];
        setInputs({ file: newFile, swX, swY, neX, neY });
      } catch (e) {
        setInputs({
          file: newFile,
          swX: shorelinePlotPropertiesInitialState.swX,
          swY: shorelinePlotPropertiesInitialState.swY,
          neX: shorelinePlotPropertiesInitialState.neX,
          neY: shorelinePlotPropertiesInitialState.neY,
        });
      }
    } else dispatch(openWarningToast('File type must be CSV'));
  };

  return (
    <ButtonFileDialog
      typePage={typePage}
      dataTxtFilesOnly
      projectId={project}
      disabled={!project}
      required
      file={file}
      setFile={onCoastlineFileChange}
    />
  );
};

export default CoastlineFileContainer;
