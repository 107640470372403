import {
  NourishmentsObject,
  ShorefaceNourishmentsObject,
  PlotObject,
  WaveGenObject,
} from '../../models/inputTypes/ShorelineFields';
import { InputFile } from '../../models/jobModels/ModelsFileTypes';

export const prepareNourishments = (list: NourishmentsObject[]) => {
  const nourishments: NourishmentsObject[] = [];

  list.forEach((item) => {
    nourishments.push({
      swX: item.swX,
      swY: item.swY,
      neX: item.neX,
      neY: item.neY,
      rate: item.rate,
      tstart: item.tstart,
      tend: item.tend,
    });
  });
  return [...nourishments];
};

export const prepareShorefaceNourishments = (list: ShorefaceNourishmentsObject[]) => {
  const nourishments: ShorefaceNourishmentsObject[] = [];

  list.forEach((item) => {
    nourishments.push({
      swX: item.swX,
      swY: item.swY,
      neX: item.neX,
      neY: item.neY,
      rate: item.rate,
      nour_len: item.nour_len,
      d: item.d,
      d50: item.d50,
      tstart: item.tstart,
      k: item.k,
    });
  });
  return [...nourishments];
};

export const prepareRefCoast = (inputFiles: Partial<InputFile>[], labels: string[], colors: string[]) => {
  return inputFiles.map((file, i) => {
    const currentFile = {
      currentFile: { project: +(file.project ?? ''), name: file.fileid },
      fileName: file.fileid ?? '',
    };
    return { file: currentFile, label: labels[i], color: colors[i] };
  });
};

export const prepareWaveGen = (
  inputFiles: Partial<InputFile>[],
  X: (number | undefined)[],
  Y: (number | undefined)[],
) => {
  return inputFiles.map((file, i) => {
    const currentFile = {
      currentFile: { project: +(file.project ?? ''), name: file.fileid },
      fileName: file.fileid ?? '',
    };
    return { file: currentFile, X: X[i], Y: Y[i] };
  });
};

export const prepareLabels = (list: PlotObject[]) => {
  const labels: string[] = [];

  list.forEach((item, i) => {
    labels.push(item.label);
  });
  return [...labels];
};

export const prepareColors = (list: PlotObject[]) => {
  const colors: string[] = [];
  list.forEach((item, i) => {
    colors.push(item.color);
  });
  return [...colors];
};

export const prepareX = (list: WaveGenObject[]) => {
  const X: (number | undefined)[] = [];
  list.forEach((item, i) => {
    X.push(item?.X);
  });
  return [...X];
};

export const prepareY = (list: WaveGenObject[]) => {
  const Y: (number | undefined)[] = [];
  list.forEach((item, i) => {
    Y.push(item.Y);
  });
  return [...Y];
};
