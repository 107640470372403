import React, { FC, memo, useState } from 'react';
import { BoxProps } from '@mui/material';
import CustomFileSelectInput from '../custom/CustomFileSelectInput';
import ImportFileModal from '../dialogs/ImportFileModal';
import BoxComponent from '../custom/BoxComponent';
import { FileType } from '../../models/inputTypes/FileType';

type ButtonFileDialogProps = {
  file?: FileType;
  setFile: (val: FileType | undefined) => void;
};

type CustomFileSelectInputProps = {
  buttonTitle?: string;
  selectedTitle?: string;
  disabled?: boolean;
  textWidth?: string;
  projectId?: string;
  required?: boolean;
  multipleFiles?: boolean;
  dataFilesOnly?: boolean;
  dataDxfFilesOnly?: boolean;
  imageFileOnly?: boolean;
  simFileOnly?: boolean;
  typePage: 'BTG' | 'SRF' | 'EVI' | 'PMS' | 'HMS' | 'HYD' | 'SDT' | 'PLOT' | 'CSHORE' | 'ALL';
};

const ButtonFileDialog: FC<ButtonFileDialogProps & BoxProps & CustomFileSelectInputProps> = ({
  required = false,
  multipleFiles,
  projectId,
  file,
  dataFilesOnly = false,
  dataDxfFilesOnly = false,
  imageFileOnly = false,
  simFileOnly = false,
  setFile,
  typePage,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showValue, setShowValue] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };

  const onChooseClick = (value: FileType | undefined) => {
    setFile(value);
    onClose();
  };
  const onOpen = () => {
    setIsOpen(true);
  };
  return (
    <BoxComponent>
      <CustomFileSelectInput
        required={required}
        selectedTitle={showValue ? file?.fileName : ''}
        onClick={onOpen}
        {...props}
      />
      <ImportFileModal
        typePage={typePage}
        simFilesOnly={simFileOnly}
        imageFileOnly={imageFileOnly}
        dataFilesOnly={dataFilesOnly}
        dataDxfFilesOnly={dataDxfFilesOnly}
        setSelectedFile={setFile}
        multipleFiles={multipleFiles}
        setShowValue={setShowValue}
        projectId={projectId}
        selectedFile={file}
        open={isOpen}
        onButtonClick={onChooseClick}
        onClose={onClose}
      />
    </BoxComponent>
  );
};

export default memo(ButtonFileDialog);
