import { ModelEnum } from '../../models/types/ModelEnum';
import { srfSimulationPreparation } from '../simulationFileToState/srfSimulationPreparation';
import { btgSimulationPreparation } from '../simulationFileToState/btgSimulationPreparation';
import { pmsSimulationPreparation } from '../simulationFileToState/pmsSimulationPreparation';
import { hmsSimulationPreparation } from '../simulationFileToState/hmsSimulationPreparation';
import { hydSimulationPreparation } from '../simulationFileToState/hydSimulationPreparation';
import { sdtSimulationPreparation } from '../simulationFileToState/sdtSimulationPreparation';
import { marisSimulationPreparation } from '../simulationFileToState/plotSimulationPreparation';
import { eviSimulationPreparation } from '../simulationFileToState/eviSimulationPreparation';
import { cshoreSimulationPreparation } from '../simulationFileToState/cshoreSimulationPreparation';

const switchFilePreparation = (data: any, modelType: ModelEnum) => {
  switch (modelType) {
    case ModelEnum.BTG:
      return btgSimulationPreparation(data);
    case ModelEnum.SRF:
      return srfSimulationPreparation(data);
    case ModelEnum.EVI:
      return eviSimulationPreparation(data);
    case ModelEnum.PMS:
      return pmsSimulationPreparation(data);
    case ModelEnum.HMS:
      return hmsSimulationPreparation(data);
    case ModelEnum.HYD:
      return hydSimulationPreparation(data);
    case ModelEnum.SDT:
      return sdtSimulationPreparation(data);
    case ModelEnum.CSHORE:
      return cshoreSimulationPreparation(data);
    case ModelEnum.PLOT:
      return marisSimulationPreparation(data);
    default:
      return btgSimulationPreparation(data);
  }
};

export default switchFilePreparation;
