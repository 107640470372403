import React, { FC, memo, useMemo, useState } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import { FileType } from '../../../models/inputTypes/FileType';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { CshoreSimulationPreparationResponse } from '../../../utils/simulationFileToState/cshoreSimulationPreparation';
import { CshoreNumericalDomain } from '../../../models/inputTypes/CshoreFields';
import { useHistory, useParams } from 'react-router';
import BathymetricFileContainer from '../../common/fileComponents/BathymetricFileContainer';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type CshoreNumericalDomainInputsProps = {
  inputState: CshoreNumericalDomain;
  setInputState: (value: CshoreNumericalDomain) => void;
  simulationChangeFile?: (value: CshoreSimulationPreparationResponse, file?: FileType) => void;
};

const CshoreNumericalDomainInputsGroup: FC<CshoreNumericalDomainInputsProps> = ({
  simulationChangeFile,
  inputState,
  setInputState,
}) => {
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();
  const history = useHistory();
  const [valueX, setValueX] = useState(1);
  const [valueY, setValueY] = useState(1);
  const { data, isFetching } = useGetProjectsQuery({});

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value, simulationSetup: undefined });
    if (name && fileId) {
      history.push(`/numerical-models/cshore-cloud`);
    }
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // if (event.target.name == 'cellX') {
    //   const newVal = event.target.value as any;
    //   setInputState({ ...inputState, cellX: newVal, neX: newVal });
    //   return;
    // }
    // if (event.target.name == 'cellY') {
    //   const newVal = event.target.value as any;
    //   setInputState({ ...inputState, cellY: newVal, neY: newVal });
    //   return;
    // }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (simulationChangeFile && loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, simulationSetup: undefined });
  };

  const onBathymetricFileChange = ({ file, cellX, cellY }: { file: FileType; cellX?: number; cellY?: number }) => {
    console.log(file, cellX, cellY);
    if (!cellX) return setInputState({ ...inputState, profileFile: file });
    setInputState({ ...inputState, profileFile: file, cellX: cellX });
  };

  // const onInputChangeX = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   setValueX(+event.target.value);
  //   setInputState({ ...inputState, [event.target.name]: event.target.value });
  // };
  // const onInputChangeY = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   setValueY(+event.target.value);
  //   setInputState({ ...inputState, [event.target.name]: event.target.value });
  // };

  return (
    <>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              value={inputState.project}
              onChange={onInputChange}
              name={'project'}
              minWidth={'180px'}
              isLoading={isFetching}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert simulation setup'}>
            <SimulationFileContainer
              typePage={'CSHORE'}
              modelType={ModelEnum.CSHORE}
              redirectLocation={'/numerical-models/cshore-cloud'}
              project={inputState.project}
              file={inputState.simulationSetup}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box mt={2} sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <Box sx={{ ...styles.flexStartBox }}>
            <Box>
              <LabelWrapper label={'Number of cells'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInput
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.cellX}
                    onChange={onNumberChange}
                    name={'cellX'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
            <Box ml={4}>
              <LabelWrapper label={'Cell size (m)'}>
                <CustomInput
                  required
                  min={0.0000000001}
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.cellSizeX}
                  onChange={onNumberChange}
                  name={'cellSizeX'}
                />
              </LabelWrapper>
            </Box>
          </Box>
        </Box>
        <Box>
          <LabelWrapper label={'Insert profile file'}>
            <BathymetricFileContainer
              typePage={'CSHORE'}
              requestDims={false}
              requestLen={!inputState.simulationSetup}
              setBathymetricInputs={onBathymetricFileChange}
              project={inputState.project}
              file={inputState.profileFile}
            />
          </LabelWrapper>
        </Box>

        {/* <Box>
          <Typography my={1} variant={'subtitle1'}>
            Subarea bounding points
          </Typography>
          <Box sx={{ ...styles.flexStartBox }}>
            <Box>
              <LabelWrapper label={'Southwestern point'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.swX}
                    onChange={onNumberChange}
                    name={'swX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    required
                    min={1}
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    value={inputState.swY}
                    onChange={onNumberChange}
                    name={'swY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
            <Box ml={4}>
              <LabelWrapper label={'Northeastern point'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    required
                    min={inputState.swX}
                    max={inputState.cellX}
                    step={1}
                    errorText={
                      inputState.cellX < valueX
                        ? 'Number should be <= than total grid length'
                        : inputState.swX > valueX
                        ? 'Number should be >= than first point'
                        : 'Enter positive integer'
                    }
                    type={'number'}
                    value={inputState.neX}
                    onChange={onInputChangeX}
                    name={'neX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    required
                    min={inputState.swY}
                    max={inputState.cellY}
                    step={1}
                    errorText={
                      inputState.cellY < valueY
                        ? 'Number should be <= than total grid length'
                        : inputState.swY > valueY
                        ? 'Number should be >= than first point'
                        : 'Enter positive integer'
                    }
                    type={'number'}
                    value={inputState.neY}
                    onChange={onInputChangeY}
                    name={'neY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
        </Box> */}
      </Box>
    </>
  );
};

export default memo(CshoreNumericalDomainInputsGroup);
