import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import CustomPrompt from '../../components/custom/CustomPrompt';
import { Collapse, Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import ShorelineNumericalDomainInputsGroup from '../../components/groups/shoreline/ShorelineNumericalDomainInputsGroup';
import ShorelineSimulationTimeInputsGroup from '../../components/groups/shoreline/ShorelineSimulationTimeInputsGroup';
import { FileType } from '../../models/inputTypes/FileType';
import { openWarningToast } from '../../redux/slices/appSlice';
import { ShorelineSimulationPreparationResponse } from '../../utils/simulationFileToState/shorelineSimulationPreparation';
import {
  shorelineNumericalDomainInitialState,
  shorelineSimulationTimeInitialState,
  shorelineWaveClimaInitialState,
  shorelineModelFeaturesInitialState,
  shorelineStructureInitialState,
  shorelineNourishmentInitialState,
  shorelinePlotPropertiesInitialState,
  shorelinePlotLabelPdfInitialState,
  shorelineOutputInitialState,
} from '../../utils/initialStates/shorelineInputState';
import {
  ShorelineNumericalDomain,
  ShorelineSimulationTime,
  ShorelineWaveClima,
  ShorelineModelFeatures,
  ShorelineStructure,
  ShorelineNourishment,
  ShorelinePlotProperties,
  ShorelinePlotLabelPdf,
  ShorelineOutput,
} from '../../models/inputTypes/ShorelineFields';
import ConfirmationModal from '../../components/dialogs/ConfirmationModal';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { useCreateJobsMutation } from '../../redux/RTK/mutations/jobsMutations';
import { useAppDispatch } from '../../redux/store';
import { shorelinePreparation } from '../../utils/modelPreparation/shorelinePreparation';
import ShorelineWaveClimaInputsGroup from '../../components/groups/shoreline/ShorelineWaveClimaInputsGroup';
import ShorelineStructureInputsGroup from '../../components/groups/shoreline/ShorelineStructureInputsGroup';
import ShorelineNourishmentInputsGroup from '../../components/groups/shoreline/ShorelineNourishmentInputsGroup';
import ShorelineModelFeaturesInputsGroup from '../../components/groups/shoreline/ShorelineModelFeaturesInputsGroup';
import ShorelinePlotPropertiesInputsGroup from '../../components/groups/shoreline/ShorelinePlotPropertiesInputsGroup';
import ShorelinePlotLabelInputsGroup from '../../components/groups/shoreline/ShorelinePlotLabelInputsGroup';
import ShorelineOutputGroup from '../../components/groups/shoreline/ShorelineOutputGroup';
import PmsSubmissionInputsGroup from '../../components/groups/pms/PmsSubmissionInputsGroup';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { pmsSubmissionInitialState } from '../../utils/initialStates/pmsInputStates';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';
import { useStorageQuery } from '../../redux/RTK/queries/storageQuery';

const MarisShorelinePage: FC = () => {
  const { data } = useGetProjectsQuery({});
  const [uploadFile] = useAddProjectFileMutation();
  const [createJob] = useCreateJobsMutation();
  const dispatch = useAppDispatch();
  const { data: profile } = useStorageQuery({});

  const [numericalState, setNumericalState] = useState<ShorelineNumericalDomain>(shorelineNumericalDomainInitialState);
  const [simulationTimeState, setSimulationTimeState] = useState<ShorelineSimulationTime>(
    shorelineSimulationTimeInitialState,
  );
  const [waveClimaState, setWaveClimaState] = useState<ShorelineWaveClima>(shorelineWaveClimaInitialState);
  const [modelFeaturesState, setModelFeaturesState] = useState<ShorelineModelFeatures>(
    shorelineModelFeaturesInitialState,
  );
  const [structureState, setStructureState] = useState<ShorelineStructure>(shorelineStructureInitialState);
  const [nourishmentState, setNourishmentState] = useState<ShorelineNourishment>(shorelineNourishmentInitialState);
  const [plotPropertiesState, setPlotPropertiesState] = useState<ShorelinePlotProperties>(
    shorelinePlotPropertiesInitialState,
  );
  const [labelPdfState, setLabelPdfState] = useState<ShorelinePlotLabelPdf>(shorelinePlotLabelPdfInitialState);

  const [outputState, setOutputState] = useState<ShorelineOutput>(shorelineOutputInitialState);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const [saveNoOutput, setSaveNoOutput] = useState(false);
  const [submissionState, setSubmissionState] = useState<PmsSubmission>(pmsSubmissionInitialState);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.SHORELINES);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (profile?.profile) {
      setDisable(
        !profile.profile.enable_models.includes('shorelineS') && !profile.profile.enable_models.includes('all'),
      );
    }
  }, [profile]);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const hasNumChanged = numericalState != shorelineNumericalDomainInitialState;
      const hasModChanged = simulationTimeState != shorelineSimulationTimeInitialState;
      const hasWaveChanged = waveClimaState != shorelineWaveClimaInitialState;
      const hasmodFeaturesChanged = modelFeaturesState != shorelineModelFeaturesInitialState;
      const hasStructChanged = structureState != shorelineStructureInitialState;
      const hasNourishChanged = nourishmentState != shorelineNourishmentInitialState;
      const hasPlotChanged = plotPropertiesState != shorelinePlotPropertiesInitialState;
      const labelPdfChanged = labelPdfState != shorelinePlotLabelPdfInitialState;
      const hasOutputChanged = outputState != shorelineOutputInitialState;
      const hasSubChanged = submissionState != pmsSubmissionInitialState;
      setStateHasChanged(
        hasNumChanged ||
          hasModChanged ||
          hasWaveChanged ||
          hasmodFeaturesChanged ||
          hasStructChanged ||
          hasNourishChanged ||
          hasPlotChanged ||
          labelPdfChanged ||
          hasOutputChanged ||
          hasSubChanged,
      );
    }
  }, [
    numericalState,
    simulationTimeState,
    waveClimaState,
    modelFeaturesState,
    structureState,
    nourishmentState,
    plotPropertiesState,
    labelPdfState,
    outputState,
    submissionState,
  ]);

  const onSimulationFileChange = (value: ShorelineSimulationPreparationResponse, file?: FileType) => {
    setNumericalState({ ...value.numerical, simulationSetup: file });
    setSimulationTimeState(value.simulationTime);
    setWaveClimaState(value.waveClimate);
    setModelFeaturesState(value.modelFeatures);
    setStructureState(value.structure);
    setNourishmentState(value.nourishment);
    setPlotPropertiesState(value.plotPropetries);
    setLabelPdfState(value.pdfLabel);
    setOutputState(value.output);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  const onSubmitClick = (e: FormEvent<any>) => {
    e.preventDefault();
    const preparedValue = shorelinePreparation(
      numericalState,
      simulationTimeState,
      waveClimaState,
      modelFeaturesState,
      structureState,
      nourishmentState,
      plotPropertiesState,
      labelPdfState,
      outputState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
  };

  const onSimulationSave = () => {
    const preparedValue = shorelinePreparation(
      numericalState,
      simulationTimeState,
      waveClimaState,
      modelFeaturesState,
      structureState,
      nourishmentState,
      plotPropertiesState,
      labelPdfState,
      outputState,
      submissionState,
    );
    if (preparedValue && numericalState.project && submissionState.simulationFile) {
      uploadFile({
        id: numericalState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !numericalState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === numericalState.project.toString());
      return found?.name;
    }
  }, [numericalState.project]);

  const onNoOutputAccept = () => {
    const preparedValue = shorelinePreparation(
      numericalState,
      simulationTimeState,
      waveClimaState,
      modelFeaturesState,
      structureState,
      nourishmentState,
      plotPropertiesState,
      labelPdfState,
      outputState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
    setSaveNoOutput(false);
  };

  return (
    <PageContainer title={'NUMERICAL MODELS'} subTitle={'ShorelineS cloud'} projectName={selectedProjectName}>
      <CustomPrompt
        when={stateHasChanged}
        message={(params) =>
          params.pathname == '/numerical-models/shorelineS-cloud' ? true : 'Changes that you made may not be saved.'
        }
      />
      <form onSubmit={onSubmitClick}>
        <Typography variant={'h4'}>Numerical Domain</Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <ShorelineNumericalDomainInputsGroup
            simulationChangeFile={onSimulationFileChange}
            plotPropertiesState={plotPropertiesState}
            setPlotPropertiesState={setPlotPropertiesState}
            inputState={numericalState}
            setInputState={setNumericalState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Simulation Time
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <ShorelineSimulationTimeInputsGroup inputState={simulationTimeState} setInputState={setSimulationTimeState} />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Model Features
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <ShorelineModelFeaturesInputsGroup
            projectId={numericalState.project}
            inputState={modelFeaturesState}
            setInputState={setModelFeaturesState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Wave Input
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <ShorelineWaveClimaInputsGroup
            projectId={numericalState.project}
            inputState={waveClimaState}
            setInputState={setWaveClimaState}
            trformulation={modelFeaturesState.sediment}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Structures
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <ShorelineStructureInputsGroup
            projectId={numericalState.project}
            inputState={structureState}
            setInputState={setStructureState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Nourishment
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <ShorelineNourishmentInputsGroup
            projectId={numericalState.project}
            inputState={nourishmentState}
            setInputState={setNourishmentState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Plot Properties
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <ShorelinePlotPropertiesInputsGroup
            projectId={numericalState.project}
            inputState={plotPropertiesState}
            setInputState={setPlotPropertiesState}
          />
        </BoxInputContainer>

        {plotPropertiesState.pdf ? (
          <>
            <Typography my={2} variant={'h4'}>
              .PDF options
            </Typography>
            <BoxInputContainer borderRadius={'4px'}>
              <ShorelinePlotLabelInputsGroup
                projectId={numericalState.project}
                reftime={simulationTimeState.reftime}
                simend={simulationTimeState.simend}
                fignryear={plotPropertiesState.fignryear}
                inputState={labelPdfState}
                setInputState={setLabelPdfState}
              />
            </BoxInputContainer>
          </>
        ) : undefined}

        <Typography mt={2} variant={'h4'}>
          Output
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <ShorelineOutputGroup inputState={outputState} setInputState={setOutputState} />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsSubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
            disable={disable}
          />
        </BoxInputContainer>
      </form>
      <ConfirmationModal
        open={saveNoOutput}
        handleClose={() => setSaveNoOutput(false)}
        message={'You are about to make a job without output! Are you sure you want to do that?'}
        handleAccept={onNoOutputAccept}
      />
    </PageContainer>
  );
};

export default MarisShorelinePage;
