import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Collapse, Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import CshoreModelDefinitionInputsGroup from '../../components/groups/cshore/CshoreModelDefinitionInputsGroup';
// import CustomTabs from '../../components/custom/CustomTabs';
import PmsSubmissionInputsGroup from '../../components/groups/pms/PmsSubmissionInputsGroup';
import CustomPrompt from '../../components/custom/CustomPrompt';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { pmsSubmissionInitialState } from '../../utils/initialStates/pmsInputStates';
import {
  cshoreModelDefinitionInitialState,
  cshoreNumericalDomainInitialState,
  cshoreDissipationInitialState,
  cshoreModelFeaturesInitialState,
} from '../../utils/initialStates/cshoreInputState';
import {
  CshoreModelDefinition,
  CshoreNumericalDomain,
  CshoreDissipation,
  CshoreModelFeatures,
} from '../../models/inputTypes/CshoreFields';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { cshorePreparation } from '../../utils/modelPreparation/cshorePreparation';
import { CshoreSimulationPreparationResponse } from '../../utils/simulationFileToState/cshoreSimulationPreparation';
import { FileType } from '../../models/inputTypes/FileType';
import { openWarningToast } from '../../redux/slices/appSlice';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { useCreateJobsMutation } from '../../redux/RTK/mutations/jobsMutations';
import ConfirmationModal from '../../components/dialogs/ConfirmationModal';
import { useAppDispatch } from '../../redux/store';
import CshoreNumericalDomainInputsGroup from '../../components/groups/cshore/CshoreNumericalDomainInputsGroup';
import CshoreEnergyDissipationInputsGroup from '../../components/groups/cshore/CshoreEnergyDissipationInputsGroup';
import CshoreModelFeaturesInputsGroup from '../../components/groups/cshore/CshoreModelFeaturesInputsGroup';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';
import { useStorageQuery } from '../../redux/RTK/queries/storageQuery';

const MarisCshorePage: FC = () => {
  const { data } = useGetProjectsQuery({});
  const { data: profile } = useStorageQuery({});
  const [uploadFile] = useAddProjectFileMutation();
  const [createJob] = useCreateJobsMutation();
  const dispatch = useAppDispatch();

  const [numericalState, setNumericalState] = useState<CshoreNumericalDomain>(cshoreNumericalDomainInitialState);
  const [modelDefinitionState, setModelDefinitionState] = useState<CshoreModelDefinition>(
    cshoreModelDefinitionInitialState,
  );
  const [energyDissipationState, setEnergyDissipationState] =
    useState<CshoreDissipation>(cshoreDissipationInitialState);
  const [modelFeaturesState, setModelFeaturesState] = useState<CshoreModelFeatures>(cshoreModelFeaturesInitialState);
  const [submissionState, setSubmissionState] = useState<PmsSubmission>(pmsSubmissionInitialState);
  const [saveNoOutput, setSaveNoOutput] = useState(false);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.CSHORE);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (profile?.profile) {
      setDisable(!profile.profile.enable_models.includes('cshore') && !profile.profile.enable_models.includes('all'));
    }
  }, [profile]);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const numChanged = numericalState != cshoreNumericalDomainInitialState;
      const defChanged = modelDefinitionState != cshoreModelDefinitionInitialState;
      const dissChanged = energyDissipationState != cshoreDissipationInitialState;
      const featChanged = modelFeaturesState != cshoreModelFeaturesInitialState;
      const subChanged = submissionState != pmsSubmissionInitialState;
      setStateHasChanged(numChanged || defChanged || dissChanged || featChanged || subChanged);
    }
  }, [numericalState, modelDefinitionState, energyDissipationState, submissionState]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === numericalState.project.toString());
      return found?.name;
    }
  }, [numericalState.project]);

  const onSimulationSave = () => {
    const preparedValue = cshorePreparation(
      numericalState,
      modelDefinitionState,
      modelFeaturesState,
      energyDissipationState,
      submissionState,
    );
    if (preparedValue && numericalState.project && submissionState.simulationFile) {
      uploadFile({
        id: numericalState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !numericalState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const onSimulationFileChange = (value: CshoreSimulationPreparationResponse, file?: FileType) => {
    setNumericalState({ ...value.numerical, simulationSetup: file });
    setModelDefinitionState(value.modelDefinition);
    setEnergyDissipationState(value.energyDissipation);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  const onNoOutputAccept = () => {
    const preparedValue = cshorePreparation(
      numericalState,
      modelDefinitionState,
      modelFeaturesState,
      energyDissipationState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
    setSaveNoOutput(false);
  };

  const onSubmitClick = (e: FormEvent<any>) => {
    e.preventDefault();
    const preparedValue = cshorePreparation(
      numericalState,
      modelDefinitionState,
      modelFeaturesState,
      energyDissipationState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
  };

  return (
    <PageContainer title={'NUMERICAL MODELS'} subTitle={'CSHORE cloud'} projectName={selectedProjectName}>
      <CustomPrompt
        when={stateHasChanged}
        message={(params) =>
          params.pathname == '/numerical-models/cshore-cloud' ? true : 'Changes that you made may not be saved.'
        }
      />
      <form onSubmit={onSubmitClick}>
        <Typography variant={'h4'}>Numerical Domain</Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <CshoreNumericalDomainInputsGroup
            simulationChangeFile={onSimulationFileChange}
            inputState={numericalState}
            setInputState={setNumericalState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Model definition
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <CshoreModelDefinitionInputsGroup inputState={modelDefinitionState} setInputState={setModelDefinitionState} />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Model Features
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <CshoreModelFeaturesInputsGroup
            sediment={modelDefinitionState.sed}
            // projectId={numericalState.project}
            inputState={modelFeaturesState}
            setInputState={setModelFeaturesState}
            // calculationDisabled={calculationDisabled}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Energy Dissipation
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <CshoreEnergyDissipationInputsGroup
            projectId={numericalState.project}
            inputState={energyDissipationState}
            setInputState={setEnergyDissipationState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsSubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
            disable={disable}
          />
        </BoxInputContainer>
      </form>
      <ConfirmationModal
        open={saveNoOutput}
        handleClose={() => setSaveNoOutput(false)}
        message={'You are about to make a job without output! Are you sure you want to do that?'}
        handleAccept={onNoOutputAccept}
      />
    </PageContainer>
  );
};

export default MarisCshorePage;
