const createRedirectLink = (simulation: string) => {
  switch (simulation) {
    case 'btg':
      return 'designTools/maris-btg';
    case 'srf':
      return 'designTools/maris-srf';
    case 'evi':
      return 'designTools/maris-evi';
    case 'pms':
      return 'numerical-models/maris-pms';
    case 'hms':
      return 'numerical-models/maris-hms';
    case 'hyd':
      return 'numerical-models/maris-hyd';
    case 'sdt':
      return 'numerical-models/maris-sdt';
    case 'cshore':
      return 'numerical-models/cshore-cloud';
    case 'plot':
      return 'plots-animation/maris-plot';
    default:
      return 'designTools/maris-btg';
  }
};

export default createRedirectLink;
