import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogProps,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import CustomDropzoneInput from '../custom/CustomDropzoneInput';
import CustomTable from '../custom/CustomTable';
import CustomButton from '../custom/CustomButton';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { FileType, ModalFile } from '../../models/inputTypes/FileType';
import moment from 'moment';
import CustomInput from '../custom/CustomInput';
import { useGetFilesQuery } from '../../redux/RTK/queries/fileQuery';
import {
  useAddProjectFileMutation,
  useGetProjectFileCustomMutation,
} from '../../redux/RTK/mutations/projectFileMutations';
import { fileToByteArray } from '../../utils/functions/fileToByteArray';
import { openErrorToast, setGlobalLoading } from '../../redux/slices/appSlice';
import { useAppDispatch } from '../../redux/store';
import { sortProjectFiles } from '../../utils/functions/sortProjectFiles';
import { findFileType } from '../../utils/functions/findFileType';
import Dot from '../common/Dot';
import useDebounce from '../../utils/hooks/debounce';
import createRedirectLink from '../../utils/functions/createRedirectLink';
import { useHistory } from 'react-router';
import { asyncGetFilePreview, openHeatmapModal } from '../../redux/slices/modalSlice';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

const styles = {
  root: {
    px: 2,
    py: 4,
  },
  container: {
    display: 'flex',
  },
  tableContainer: {
    marginRight: 1,
    width: '70%',
    h4: {
      paddingBottom: 2,
    },
  },
  middle: {
    display: 'flex',
    alignItems: 'center',
  },
  pickerContainer: {
    h4: {
      paddingBottom: 2,
    },
    marginLeft: 1,
  },
  bottomButton: {
    mx: 'auto',
    mt: 3,
    display: 'flex',
  },
};

const disabledButton = {
  '&:disabled': {
    opacity: '0.2',
  },
};

const tableHeader = [
  { title: 'NAME', align: 'flex-start', sort: true },
  { title: 'TYPE', align: 'center', sort: true },
  { title: 'DATE', align: 'center', sort: true },
  { title: '', align: '' },
];

type ImportFileModalProps = {
  projectId?: string;
  onButtonClick?: (value: FileType | undefined) => void;
  title?: string;
  selectedFile?: FileType | undefined;
  setSelectedFile: (val: FileType | undefined) => void;
  setShowValue: (val: boolean) => void;
  multipleFiles?: boolean;
  dataFilesOnly?: boolean;
  dataTxtFilesOnly?: boolean;
  dataDxfFilesOnly?: boolean;
  imageFileOnly?: boolean;
  simFilesOnly?: boolean;
  typePage: 'BTG' | 'SRF' | 'EVI' | 'PMS' | 'HMS' | 'HYD' | 'SDT' | 'PLOT' | 'SHORELINES' | 'ALL';
};

const ImportFileModal: FC<ImportFileModalProps & DialogProps> = ({
  multipleFiles,
  typePage,
  projectId,
  title = 'Saved files on cloud',
  onButtonClick,
  setShowValue,
  selectedFile,
  dataFilesOnly = false,
  dataTxtFilesOnly = false,
  imageFileOnly = false,
  simFilesOnly = false,
  dataDxfFilesOnly = false,
  ...props
}) => {
  const history = useHistory();
  const [getFileData] = useGetProjectFileCustomMutation();

  const { data: rawData, isFetching } = useGetFilesQuery({ projectId }, { refetchOnMountOrArgChange: 1 });
  const [uploadFile] = useAddProjectFileMutation();
  const dispatch = useAppDispatch();
  const [selectedFileState, setSelectedFileState] = useState<ModalFile | undefined>(undefined);
  const [multipleFileState, setMultipleFileState] = useState<File[] | undefined>(undefined);
  const [newName, setNewName] = useState('');
  const [sortBy, setSortBy] = useState({ title: '', ascending: false });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const data = useMemo(() => {
    if (dataFilesOnly && rawData?.length) {
      return rawData.filter((item) => {
        const fileType = findFileType(item.name);
        const isDataFileType = fileType === 'data';
        const isOnSearch = item.name.toLowerCase().includes(debouncedSearch.toLowerCase());
        if (isDataFileType && isOnSearch) return true;
      });
    }
    if (dataTxtFilesOnly && rawData?.length) {
      return rawData.filter((item) => {
        const fileType = findFileType(item.name);
        const isDataFileType = fileType === 'txt' || fileType === 'data' || fileType === 'kml';
        const isOnSearch = item.name.toLowerCase().includes(debouncedSearch.toLowerCase());
        if (isDataFileType && isOnSearch) return true;
      });
    }
    if (dataDxfFilesOnly && rawData?.length) {
      return rawData.filter((item) => {
        const fileType = findFileType(item.name);
        const isDataFileType = fileType === 'dxf' || fileType === 'data';
        const isOnSearch = item.name.toLowerCase().includes(debouncedSearch.toLowerCase());
        if (isDataFileType && isOnSearch) return true;
      });
    }

    if (imageFileOnly && rawData?.length) {
      return rawData.filter((item) => {
        const name = item.name.toLowerCase();
        const fileType = findFileType(name);
        const isDataFileType = fileType === 'image';
        const isOnSearch = item.name.toLowerCase().includes(debouncedSearch.toLowerCase());
        if (isDataFileType && isOnSearch) return true;
      });
    }
    if (simFilesOnly && rawData?.length) {
      return rawData.filter((item) => {
        const fileType = findFileType(item.name);
        const isDataFileType = fileType === 'setup';
        let typeSimulation = false;
        if (typePage === 'ALL') {
          typeSimulation = true;
        } else {
          typeSimulation = item.simulation?.toUpperCase() === typePage;
        }

        const isOnSearch = item.name.toLowerCase().includes(debouncedSearch.toLowerCase());
        if (isDataFileType && typeSimulation && isOnSearch) return true;
      });
    }
    if (debouncedSearch)
      return rawData?.filter((item) => item.name.toLowerCase().includes(debouncedSearch.toLowerCase()));
    return rawData;
  }, [rawData, dataFilesOnly, imageFileOnly, simFilesOnly, debouncedSearch]);

  useEffect(() => {
    setMultipleFileState(undefined);
    if (selectedFile && data?.length) {
      if (selectedFile.currentFile?.uuid || (selectedFile?.currentFile?.name && selectedFile?.currentFile?.project)) {
        const foundFile = data.find(
          ({ name, project, uuid }) =>
            selectedFile.currentFile.uuid === uuid ||
            (selectedFile.currentFile.name === name && selectedFile.currentFile.project === project),
        );
        if (foundFile) {
          setShowValue(true);
          setSelectedFileState(foundFile);
          return;
        }
      }
    }
    setSelectedFileState(undefined);
    setShowValue(false);
  }, [data, selectedFile, props.open]);

  const tableRows = useMemo(() => {
    if (isFetching) {
      return (
        <TableRow>
          <TableCell align={'center'} colSpan={10}>
            <CircularProgress color={'info'} />
          </TableCell>
        </TableRow>
      );
    }
    if (!data?.length) {
      return (
        <TableRow>
          <TableCell align={'center'} colSpan={10}>
            <Typography variant={'subtitle2'}>No available files</Typography>
          </TableCell>
        </TableRow>
      );
    }
    if (data?.length) {
      const sortedFiles = sortProjectFiles(data.slice(), sortBy.title, sortBy.ascending);
      return sortedFiles.map((item, i) => {
        const fileCategory = findFileType(item.name.toLowerCase());
        let type = '';
        if (item.simulation !== null) {
          type = item.simulation === 'shorelineS' ? 'shorelineSCloud' : item.simulation.toUpperCase();
        }
        const isViewable =
          !item.name.endsWith('.json') &&
          !item.name.endsWith('.csv') &&
          !item.name.endsWith('.txt') &&
          !item.name.endsWith('.kml');
        const isDisable = item.name.endsWith('.dxf') || item.name.endsWith('.txt') || item.name.endsWith('.kml');

        return (
          <TableRow
            onClick={() => {
              setMultipleFileState(undefined);
              setSelectedFileState(item);
            }}
            selected={item.uuid === selectedFileState?.uuid}
            key={i}
          >
            <TableCell>
              <Grid container alignItems={'center'}>
                <Dot type={fileCategory} mr={1.5} />
                <Typography variant={'subtitle2'}>{item.name}</Typography>
              </Grid>
            </TableCell>
            <TableCell align={'center'}>
              {fileCategory === 'txt' || fileCategory === 'kml' ? (
                <Typography variant={'subtitle2'}>data</Typography>
              ) : (
                <Typography variant={'subtitle2'}>{type ? fileCategory + '_' + type : fileCategory}</Typography>
              )}
            </TableCell>
            <TableCell align={'center'}>
              <Typography variant={'subtitle2'}>{moment(item.created_at).format('DD/MM/yyyy')}</Typography>
            </TableCell>
            <TableCell align={'center'}>
              <IconButton
                sx={{ ...disabledButton }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.name.endsWith('.json')) return getJsonData(item.name, item.uuid, item.project);
                  if (item.name.endsWith('.csv')) return dispatch(openHeatmapModal(item));
                  return dispatch(asyncGetFilePreview({ project: item.project, fileName: item.name }));
                }}
                disabled={isDisable}
              >
                {isViewable ? <RemoveRedEyeOutlined /> : <EditIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
        );
      });
    }
  }, [data, selectedFileState, isFetching, sortBy]);

  const getJsonData = async (fileName: string, fileId: string, projectFile: string) => {
    try {
      const res: any = await getFileData({ projectId: projectFile.toString(), fileName: fileName });
      if (res?.data?.simulation) {
        const redirectionLink = createRedirectLink(res.data.simulation);
        history.push(`/${redirectionLink}/${projectFile}/${fileName}/${fileId}`);
      }
    } catch (e) {
      dispatch(openErrorToast('Error opening simulation file'));
    }
  };

  const buttonMessage = useMemo(() => {
    const tempLength = multipleFileState?.length ?? 0;
    if (tempLength > 1) return 'Upload';
    if (tempLength == 1) return 'Upload and Choose';
    return 'Choose';
  }, [selectedFileState, multipleFileState]);

  const selectedDeviceFileName = useMemo(() => {
    const tempLength = multipleFileState?.length ?? 0;
    if (tempLength > 1) {
      return multipleFileState ? `${multipleFileState?.length} files selected` : '';
    }
    if (tempLength === 1) {
      return multipleFileState ? multipleFileState?.[0]?.name : '';
    }
    return;
  }, [selectedFileState, multipleFileState]);

  const closeDialog = () => {
    if (props?.onClose) {
      props?.onClose({}, 'backdropClick');
      setSearch('');
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const onChooseClick = () => {
    setSearch('');
    if (props?.onClose && selectedFileState) {
      onButtonClick
        ? onButtonClick({ currentFile: selectedFileState, fileName: selectedFileState.name ?? '' })
        : undefined;
      props?.onClose({}, 'backdropClick');
    }
    setMultipleFileState(undefined);
  };

  const onMultipleUploadClick = async () => {
    const fileLength = multipleFileState?.length;
    if (fileLength) {
      dispatch(setGlobalLoading(true));
      try {
        const byteFiles = await Promise.all(
          multipleFileState.map(async (item) => {
            return await fileToByteArray(item);
          }),
        );
        const res: any[] = await Promise.all(
          byteFiles?.map(async (item, i) => {
            return await uploadFile({
              id: projectId,
              name: fileLength == 1 ? newName : multipleFileState[i].name,
              file: item,
            });
          }),
        );
        if (res[0] && fileLength == 1 && props?.onClose) {
          onButtonClick ? onButtonClick({ currentFile: res[0]?.data, fileName: res[0]?.data?.name ?? '' }) : undefined;
          props?.onClose({}, 'backdropClick');
        }
        setMultipleFileState(undefined);
      } catch (e) {
        dispatch(openErrorToast('Failed to upload Files!'));
      }
      dispatch(setGlobalLoading(false));
    }
    setSearch('');
  };

  const onDropzoneChange = (val: File[]) => {
    setMultipleFileState(val);
    setSelectedFileState(undefined);
    if (val.length == 1) setNewName(val[0].name);
  };

  return (
    <Dialog {...props} maxWidth={'xl'} onClose={() => closeDialog()}>
      <Box sx={styles.root}>
        <Box sx={styles.container}>
          <Box sx={styles.tableContainer}>
            <Typography variant={'h4'}>{title}</Typography>
            <Box display={'flex'} justifyContent={'flex-end'} mb={1}>
              <CustomInput
                maxWidth={'150px'}
                placeholder={'search'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
            <Box>
              <CustomTable sortBy={sortBy} onSortClick={setSortBy} maxHeight={'235px'} header={tableHeader}>
                {tableRows}
              </CustomTable>
            </Box>
          </Box>
          <Box sx={styles.middle}>
            <Typography variant={'subtitle2'} color={'info.light'}>
              or
            </Typography>
          </Box>
          <Box sx={styles.pickerContainer}>
            <Typography variant={'h4'}>Import files from your device</Typography>
            <Box>
              <CustomDropzoneInput
                selectedFileName={selectedDeviceFileName}
                setMultipleFiles={onDropzoneChange}
                multipleFiles={multipleFiles}
              />
            </Box>
            {multipleFileState?.length == 1 ? (
              <Box mt={1}>
                <CustomInput value={newName} name={'newName'} onChange={onInputChange} />
              </Box>
            ) : undefined}
          </Box>
        </Box>
        <CustomButton
          disabled={!selectedFileState && !multipleFileState}
          sx={styles.bottomButton}
          onClick={multipleFileState?.length ? onMultipleUploadClick : onChooseClick}
          minWidth={'132px'}
          variant={'contained'}
          color={'primary'}
        >
          <Typography variant={'subtitle2'}>{buttonMessage}</Typography>
        </CustomButton>
      </Box>
    </Dialog>
  );
};

export default ImportFileModal;
